 <template>
    <br />
    <br />
    <div class="col-xs-12 d-block d-sm-none hdrtxt">
        <ListenText class="lt" />
    </div>
    <div class="col-sm-10 offset-sm-1 d-none d-sm-block d-md-none">
        <ListenText class="lt" />
    </div>
    <div class="col-sm-10 offset-sm-1 d-none d-md-block d-lg-none">
        <ListenText class="lt" />
    </div>
    <div class="col-sm-9 offset-sm-2 d-none d-lg-block hdrtxt">
        <ListenText class="lt" />
    </div>
    <!--<span style="color:white;">{{ windowWidth }}</span>-->

    <div class="col-sm-6 offset-sm-3 audiobox">
        <br />
        <div class="trackTitle titlePos">{{ trackName }}</div>

        <div class="audioInterface">
            <audio ref="player" id="audioPlayer" controls controlsList="nodownload noplaybackrate">
                <source :src="getSrc(trackName)" type="audio/wav">
                Your browser does not support this audio format.
            </audio>
        </div>
        <div>
            <table class="table table-borderless table-sm">
                <colgroup>
                    <col style='width:50%' />
                    <col style='width:50%' />
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col" class="tblhdr">Title</th>
                        <th scope="col" class="tblhdr">Length</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="t in trackList" :key="t.index">
                        <td><a v-bind:class="[currentTrack==t.index ? 'activeClass' : 'idleClass']" @click="changeSrc(t.index)">{{ t.title }}</a></td>
                        <td v-bind:class="[currentTrack==t.index ? 'activeClass' : 'idleClass']">{{ t.time }}</td>
                    </tr>
                </tbody>
            </table>
            <br />
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';
    import ListenText from '@/components/ListenText.vue'

    export default defineComponent({
        name: 'MusicView',
        data() {
            return {
                trackName: "The Mutation",
                currentTrack: 0,
                windowWidth: 0,
                trackList: [
                    {index: 0, title: "The Mutation", time: "4:29" },
                    { index: 1, title: "The Premonition", time: "4:05" },
                    { index: 2, title: "Into the Nexus", time: "3:10" },
                    { index: 3, title: "Draconian Measures", time: "4:15" },
                    { index: 4, title: "Uncommon Resolve", time: "4:25" }
                ]
            };
        },

        components: {
            ListenText
        },

        methods: {
            getSrc(src) {
                return require("../assets/" + src + ".wav");
            },

            changeSrc(newTrack) {
                this.currentTrack = newTrack;
                this.trackName = this.trackList[newTrack].title;
                var ap = this.$refs.player;
                ap.load();
                ap.play();
            },

            nextTrack() {
                const trackCount = this.trackList.length;
                let nextTrack = this.currentTrack + 1;
                if (nextTrack >= trackCount) {
                    nextTrack = 0;
                }
                this.changeSrc(nextTrack);
            }
        },

        mounted() {
            document.querySelector("#audioPlayer").addEventListener("ended", this.nextTrack, false);
    /*
            this.windowWidth = window.innerWidth;
            window.onresize = () => { this.windowWidth = window.innerWidth; }  
    */
        }
    });
</script>

<style>
    .lt {
        font-size:1.4em;
    }

    .audiobox {
        background-color: #b3b3b3;
        border-radius: 50px;
        background-image: linear-gradient(to bottom, rgba(187, 190, 196, 0.52), rgba(114, 116, 120, 0.73)), url('../assets/concrete_bkgrnd.jpg');
    }

    table {
        border: none;
        margin-left: 75px;
    }

    tr td {
        padding-top: 0;
        font-size: 1.2em;
    }

    .time {
        color: white;
        font-weight: bold;
    }

    .tblhdr {
        font-size: 1.3em;
        font-weight: bold;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        color: #663d00;
    }

    .activeClass {
        color: #000000;
        font-weight: bold;
        font-size: 1.2em;
    }

    .idleClass {
        color: #fffaf2;
        font-weight: bold;
        cursor: pointer;
    }

    #audioPlayer {
        text-align: center;
        margin-bottom: 10px;
    }

    .trackTitle {
        font-size: 2.4em;
        font-weight: bold;
        font-family: 'Footlight MT Light',century,'Perpetua',garamond,serif,times;
        color: #242124;
    }

    @media screen and (max-width: 575px) {
        .audioInterface {
            margin-left: 35px;
        }

        .hdrtxt {
            padding-left: 10px;
        }

        .titlePos {
            margin: 0 0 15px 75px;
        }
    }

    @media screen and (min-width: 576px) and (max-width: 617px) {
        .audioInterface {
            margin-left: 0;
        }

        .titlePos {
            margin: 0 0 15px 25px;
        }
    }

    @media screen and (min-width: 618px) and (max-width: 653px) {
        .audioInterface {
            margin-left: 5px;
        }

        .titlePos {
            margin: 0 0 15px 25px;
        }
    }

    @media screen and (min-width: 654px) and (max-width: 879px) {
        .audioInterface {
            margin-left: 20px;
        }

        .titlePos {
            margin: 0 0 15px 40px;
        }
    }

    @media screen and (min-width: 880px) and (max-width: 1249px) {
        .audioInterface {
            margin-left: 75px;
        }

        .titlePos {
            margin: 0 0 15px 75px;
        }
    }

    @media screen and (min-width: 1250px) {
        .audioInterface {
            margin-left: 75px;
        }

        .hdrtxt {
            padding-left: 90px;
        }

        .titlePos {
            margin: 0 0 15px 75px;
        }
    }

    @media screen and (min-width: 1550px) {
        .hdrtxt {
            padding-left: 150px;
        }
    }
</style>