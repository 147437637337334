<template>
    <p>
        <span class="infotxt">Please enjoy this selection of tracks from the self-titled debut of Epistorius.</span>
        <br />
        <span class="infotxt">The entire album can be found on iTunes, Amazon Music, Spotify, and most major streaming platforms.</span>
        <br />
        <span class="infotxt">Thanks for listening!</span>
    </p>
    <p class="logocontainer">
        <a href="https://open.spotify.com/track/1HudFzvEzetRyoc7zjrlyB?si=e24044b0ac914634" target="_blank" class="linkstyle">
            Listen on Spotify &nbsp;<img class="spotlogo" src="../assets/Spotify-logo.webp" />
        </a>
    </p>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ListenText',
        data() {
            return {
            };
        }
    });
</script>

<style>
    .infotxt {
        margin-bottom: 15px;
        font-size:1.3em;
    }

    .spotlogo {
        width: 50px;
        height: auto;
    }

    .logocontainer {
        margin-bottom: 30px;
        font-size: 1.2em;
    }

    .logocontainer a:hover {
        color: #fffaf2;
    }

    .linkstyle {
        color: #1db954;
    }
</style>