<template>
    <div class="row">
        <div class="col-xs-12">
            <img src="./assets/Epistorius_Banner.jpg" class="img-fluid" alt="Logo" />
        </div>
    </div>

    <div class="row">
        <div class="d-none d-sm-block">
            <nav class="navbar navbar-expand-lg epiground">
                <div class="container-fluid">
                    <div class="col-sm-12 ctr hdrlinks">
                        <router-link class="makeroom bigtext primary" aria-current="page" to="/">Home</router-link>
                        <router-link class="makeroom bigtext" to="/music">Music</router-link>
                        <router-link class="makeroom bigtext" to="/about">About</router-link>
                        <router-link class="makeroom bigtext" to="/links">Video/Links</router-link>
                        <router-link class="makeroom bigtext" to="/contact">Contact</router-link>
                    </div>
                </div>
            </nav>
        </div>

        <div class="d-block d-sm-none">
            <nav class="navbar navbar-expand-lg epiground">
                <div class="container-fluid">
                    <a class="navbar-brand" href="#"></a>
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div class="navbar-nav hdrlinks">
                            <router-link class="nav-link bigtext primary" aria-current="page" to="/">Home</router-link>
                            <router-link class="nav-link bigtext primary" to="/music">Music</router-link>
                            <router-link class="nav-link bigtext primary" to="/about">About</router-link>
                            <router-link class="nav-link bigtext primary" to="/links">Video/Links</router-link>
                            <router-link class="nav-link bigtext primary" to="/contact">Contact</router-link>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>
    <router-view />
</template>

<style>
    #app {
        font-family: 'Footlight MT Light',century,'Perpetua',garamond,serif,times;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #f8f8ff;
        background-color: #000000;
        font-size: 1.1em;
    }

    html {
        background-color: #000000;
    }

    .ctr {
        text-align: center;
    }

    nav {
      padding: 30px;
    }

    nav a {   
        color: #957765;
    }

    .primary {
        color: #957765;
    }

    nav a.router-link-exact-active {
        color: #5b493e;
    }

    .epiground {
        background-color: #c0bfbd;
    }

    .makeroom {
        margin-right: 90px;
    }

    .hdrlinks a {
        display: inline-block;
    }

    .hdrlinks a:hover {
        color: #fffaf2;
    }

    /* adds outline around menu characters
    .outline {
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        letter-spacing: 0.1em;
    }
    */

    /* For Mobile Only */
    @media screen and (max-width: 575px) {
        .bigtext {
            text-decoration: none;
            font-size: 1.8em;
            font-weight: bold;
        }
    }

    /* For All Desktop Sizes */
    @media screen and (min-width: 576px) {
        .bigtext {
            text-decoration: none;
            font-size: 2.2em;
            font-weight: bold;
        }
    }

    @import'~bootstrap/dist/css/bootstrap.css';
</style>
