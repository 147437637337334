import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import MusicView from '../views/MusicView.vue'
import InterView from '../views/InterView.vue'
import ContactView from '../views/ContactView.vue'
import AboutView from '../views/AboutView.vue'
import LinksView from '../views/LinksView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
      name: 'about',
      component: AboutView
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/interviews',
    name: 'interviews',
    component: InterView
  },
  {
     path: '/music',
     name: 'music',
     component: MusicView
  },
  {
     path: '/contact',
     name: 'contact',
     component: ContactView
    },
    {
        path: '/links',
        name: 'links',
        component: LinksView
    }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
