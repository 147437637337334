<template>
    <div class="col-xs-2 offset-8">
        <br />
        <br />
        {{ myVariable }}
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'InterView',
        data() {
            return {
                myVariable: "Coming soon"
            };
        }
    });
</script>