<template>
    <div class="row">
        <div class="col-xs-12">
            <img src="../assets/Epistorius_Cover.jpg" class="img-fluid" alt="Album Cover" />
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'HomeView',
        data() {
            return {
            };
        }
    });
</script>
