<template>
    <br />
    <br />
    <div class="row">
        <div class="col-xs-12 ctr">
            <p class="bigtext contacttxt">
                Contact via email:
                <br />
                <a class="btn btn-lg contactbtn" href=mailto:contact@epistorius.com>Send Email</a>
            </p>
        </div>
    </div>
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'ContactView',
        data() {
            return {
            };
        }
    });
</script>

<style>
    .contacttxt {
        color: #f8f1e6;
    }

    .contactbtn {
        background: #99784e;
        color: #fffaf2;
        border: none;
        text-align: center;
        height: 50px;
        cursor: pointer;
        display: inline-block;
        margin-top: 20px;
        padding-top: 10px;
    }

    .contactbtn a {
        color: #fffaf2;
        text-decoration: none;
    }

    .contactbtn:hover {
        background: #c09d6e;
    }
</style>