<template>
    <br />
    <div class="row">
        <div class="ctr clickable">
            <a href="https://www.facebook.com/profile.php?id=100094377335031" target="_blank" class="followtxt">Follow on Facebook</a>
            <br />
            <a href="https://www.instagram.com/epistorius42" target="_blank" class="followtxt">Follow on Instagram</a>
            <br />
            <a href="https://open.spotify.com/track/1HudFzvEzetRyoc7zjrlyB?si=e24044b0ac914634" target="_blank" class="listentxt">Listen on Spotify</a>
            <br />
            <a href="https://music.apple.com/us/album/epistorius/1653600619" target="_blank" class="listentxt">Listen on Apple Music</a>
            <br />
            <a href="https://epistorius.bandcamp.com/album/epistorius" target="_blank" class="listentxt">Listen at Bandcamp</a>
            <br />
            <a href="https://soundcloud.com/epistorius" target="_blank" class="listentxt">Listen on SoundCloud</a>
        </div>
    </div>
    <br />
    <div class="row col-8 offset-2">
        <iframe class="vidview" src="https://www.youtube.com/embed/CGHgrivM7-o?si=2l6a6Z9xZjuRU60G" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <br />
    <div class="row col-8 offset-2">
        <iframe class="vidview" src="https://www.youtube.com/embed/SaMhLg_9QVI?si=sjJrK3vhKF2jb-Yn" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <br />
</template>

<script lang="js">
    import { defineComponent } from 'vue';

    export default defineComponent({
        name: 'LinksView',
        data() {
            return {
            };
        }
    });
</script>

<style>
    .listentxt {
        color: #a6d9f8;
        font-size: 1.4em;
    }

    .followtxt {
        color: #dddddd;
        font-size: 1.4em;
    }

    .clickable a:hover {
        color: #fffaf2;
    }

    .vidview {
        aspect-ratio: 16 / 9;
        width: 100%;
    }
</style>